$main-color: #042d5a;
$main-color-hover: #1c4f85e6;
$main-color2: #ec7424;

body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
a {
  margin: 0 !important;
  padding: 0 !important;
}

body {
  background-color: #faf9f9 !important;
}

::-webkit-scrollbar {
  width: 18px;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  border: 5px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  box-shadow: inset 0 0 0 10px;
  cursor: pointer;
}

//Common
.success {
  background-color: rgb(2, 154, 2);
}
.warning {
  background-color: rgb(211, 211, 3);
}
.danger {
  background-color: red;
}

.success,
.danger,
.warning {
  padding: 4px 0;
  color: white;
  min-width: 110px;
  max-width: 140px;
  text-align: center;
  margin-inline: auto;
}

.panel-span {
  display: flex;
  margin-block: 10px;
  align-items: center;

  i {
    margin-right: 4px;
  }
}

img {
  -webkit-mask-image: linear-gradient(
    45deg,
    #000 25%,
    rgba(0, 0, 0, 0.2) 50%,
    #000 75%
  );
  mask-image: linear-gradient(
    45deg,
    #000 25%,
    rgba(0, 0, 0, 0.2) 50%,
    #000 75%
  );
  -webkit-mask-size: 800%;
  mask-size: 800%;
  -webkit-mask-position: 0;
  mask-position: 0;
  &:hover {
    transition: mask-position 2s ease, -webkit-mask-position 2s ease;
    -webkit-mask-position: 120%;
    mask-position: 120%;
    opacity: 1;
  }
}

//Modal
//Common
.modal {
  cursor: auto;
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 700px;
    }
  }

  .modal-body {
    padding: 0;
    .modal-body-image {
      width: 75%;
      margin: 20px auto;

      img {
        width: 100% !important;
        height: 250px;
        object-fit: contain;
      }
    }
    .modal-body-content {
      padding: 20px;
    }
  }
  .modal-content {
    margin: auto;
    padding: 20px;

    table {
      text-align: center;
    }

    .modal-header {
      border-bottom: 3px solid #042d5a;
      margin-block: 10px;
      width: 100%;
      padding: 5px 0;

      i {
        margin-right: 5px;
        font-size: 20px;
      }

      .modal-header-part-span {
        font-weight: 400;
        font-size: 22px;
        width: 100%;
        color: $main-color;
      }
    }

    .modal-footer {
      padding: 14px 2.5%;
    }
  }
}

// downward open window

.MuiPaper-root {
  margin-block: 10px;
}

.MuiButtonBase-root {
  background-color: $main-color !important;
  color: white !important;
}

.MuiAccordionDetails-root {
  padding: 16px !important;
}

.MuiTypography-root {
  width: 100%;
}

//Header

.panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: $main-color;
  color: white;
  border-radius: 7px 7px 0 0;
  padding: 10px 15px;
  font-weight: 400;
  &-left {
    display: flex;
    align-items: center;
    p {
      margin-bottom: 0 !important;
    }
  }
}

// Lang Dropdown
.menu-flags {
  background-color: white;
  padding: 0 !important;
  border-radius: 4px;
  .menu-flags-button {
    padding: 3px 8px 3px 0 !important;
  }
  ul {
    li {
      padding-block: 5px !important;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}

//Login
.login {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $main-color;
  align-items: center;
  justify-content: center;

  .login-body {
    width: 30%;
    text-align: center;

    img {
      width: 60%;
      margin-block: -100px 20px;
      animation: image-slide 4s infinite;
    }

    @keyframes image-slide {
      0% {
        transform: scale(0) rotateY(360deg);
      }
      100% {
        transform: scale(1) rotateY(0);
      }
    }

    form {
      background-color: white;
      text-align: center;
      border-radius: 15px;
      padding: 20px 0;

      h1 {
        color: $main-color;
        margin-block: 20px 50px;
        font-size: 35px;
      }

      input {
        display: block;
        width: 80%;
        margin: 10px auto;
        padding: 10px 5px;
        border-radius: 10px;
        border: 1px double transparent;
        border-bottom-color: $main-color-hover;
        outline: none;
        font-size: 14px;
        transition: 0.4s;

        &:focus,
        &:hover {
          border: 1px double $main-color;
        }
      }

      button {
        display: block;
        border-radius: 10px;
        background-color: $main-color-hover;
        color: white;
        border: 1px solid $main-color;
        cursor: pointer;
        margin: 30px auto 10px;
        padding: 10px 20px;
        transition: 0.4s;

        &:hover {
          background-color: $main-color;
        }
      }

      a {
        text-decoration: none;
        color: $main-color;
        transition: 1s;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

//HomePage
.home {
  display: flex;
  padding: 20px 20px 0;

  .left-side-home,
  .right-side-home {
    width: 25%;
  }

  .middle-home {
    width: 46%;
    margin-inline: 1%;

    //news
    .news {
      margin-bottom: 20px;
      .panel-header {
        display: flex;
        align-items: center;

        p {
          font-size: 33px;
          font-weight: 500;
        }
        img {
          width: 150px;
        }
      }

      .news-body {
        padding-block: 10px;
        cursor: grab;

        .news-body-card {
          width: 100%;
          margin-inline: auto;
          text-align: center;

          img {
            aspect-ratio: 3/2;
            object-fit: contain;
            width: 85%;
            mix-blend-mode: color-burn;
          }

          p {
            padding: 10px 20px !important;
            background-color: white;
            color: $main-color;
            font-weight: 500;
            margin-top: 10px;
            text-align: left;
          }
        }

        .swiper-button-next:after,
        .swiper-button-prev:after {
          font-size: 35px;
          color: $main-color2;
        }
        .swiper-button-next,
        .swiper-button-prev {
          top: var(--swiper-navigation-top-offset, 45%);
        }
      }
    }

    //announcements
    .announcement {
      background-color: white;

      .announcement-panel-body {
        padding: 0px 20px 24px;
        min-height: 300px;
        max-height: 475px;
        overflow-y: scroll;
        transition: all 0.4s ease;
        color: white;

        &:hover {
          color: $main-color;
        }

        &::-webkit-scrollbar {
          width: 18px;
          cursor: pointer;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
          border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
          border: 5px solid rgba(0, 0, 0, 0);
          background-clip: padding-box;
          border-radius: 9999px;
          box-shadow: inset 0 0 0 10px;
          cursor: pointer;
        }
        .announcement-body-card {
          display: flex;
          color: #737373;
          font-size: 14px;

          h4 {
            color: $main-color;
          }

          img {
            width: 40%;
            aspect-ratio: 3/2;
            object-fit: contain;
            margin-right: 5%;
          }

          .announcement-content {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin-bottom: 5px;
          }
        }
        .btn-info {
          font-size: 14px;
        }
      }
    }
  }

  .left-side-home {
    .swiper-body-part {
      display: block;
      width: 100%;
      padding: 20px 10px;
      background-color: white;

      .maintenance-panel-frame,
      .cerbox-panel-frame {
        border: 1px solid $main-color2;
        margin-bottom: 20px;
        transition: 0.4s;
        text-decoration: none;
        cursor: pointer;
        min-width: 100px;

        .maintenance-part-header,
        .cerbox-part-header {
          background-color: $main-color2;
          padding: 10px;
          font-size: 18px;
          color: white;
          transition: 0.4s;
        }

        .maintenance-body-structure,
        .cerbox-body-structure {
          padding: 10px 10px 0;
          color: $main-color;
          overflow-x: scroll;
          max-height: 150px;
          min-height: 90px;
          overflow-y: scroll;

          &::-webkit-scrollbar {
            width: 18px;
            cursor: pointer;
          }

          &::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 3px;
          }

          &::-webkit-scrollbar-thumb {
            border: 5px solid rgba(0, 0, 0, 0);
            background-clip: padding-box;
            border-radius: 9999px;
            box-shadow: inset 0 0 0 10px;
            cursor: pointer;
          }
          p {
            font-size: 14px;
            margin-bottom: 0;
          }
        }

        &:hover {
          border-color: $main-color;

          .maintenance-part-header {
            background-color: $main-color;
          }
        }
      }
    }

    .bakim-popup-card,
    .cerbox-popup-card {
      width: 100%;
      border: 2px solid $main-color2;
      display: flex;
      padding: 0;
      margin-bottom: 7px;
      font-size: 14px;

      .bakim-popup-card-left,
      .cerbox-popup-card-left {
        background-color: $main-color2;
        transition: all 0.3s ease-out 0s;
        padding-block: 8px;
        color: white;
        display: flex;
        align-items: center;

        div {
          font-size: 18px;
          text-align: center;
        }
      }
      .bakim-popup-card-right {
        display: flex;
      }

      .cerbox-popup-card-right {
        p {
          display: flex;
          align-items: center;
        }
      }

      .bakim-popup-card-right,
      .cerbox-popup-card-right {
        width: 100%;
        padding: 5px 10px;
        align-items: center;

        .danger,
        .success,
        .warning {
          margin-inline: 4px;
          min-width: 120px;
          max-width: 120px;
          padding-block: 2px;
        }
      }
    }

    .profile-dimension-popup {
      border: 1px solid $main-color2;
      margin-bottom: 20px;

      .profile-dimension-popup-header {
        background-color: $main-color2;
        text-align: center;
        color: white;
        padding-block: 10px;
        font-size: 22px;
      }
      .profile-dimension-popup-body {
        display: grid;
        gap: 30px;
        grid-template-columns: repeat(2, 1fr);
        padding: 15px;
        .card {
          td,
          th {
            border: 1px solid #e4e1e1 !important;
          }

          thead {
            th {
              background-color: #1d6c83;
              padding-block: 5px;
              font-size: 18px;
              color: white;
            }
          }

          tbody {
            tr:nth-child(1) {
              background: #dba206;
              color: white;
            }

            .aks-body {
              font-size: 13px;
              td:nth-child(odd) {
                width: 15%;
              }
              td:nth-child(even) {
                width: 35%;
                .danger,
                .success,
                .warning {
                  margin-inline: auto;
                  min-width: 100px;
                  max-width: 100px;
                  padding-block: 1px;
                }
              }
            }
          }
        }
      }
    }

    .train-detail-panel {
      margin-block: 15px;
      background-color: white;
      .train-detail-panel-body {
        padding-inline: 15px;

        .train-panel-card:nth-child(1) {
          margin-top: 20px;
        }
        .train-panel-card:nth-child(3) {
          margin-bottom: 20px;
        }

        .train-panel-card {
          display: flex;
          padding-block: 15px;
          background-color: #0086c0;
          margin-block: 10px;
          color: white;
          align-items: center;
          border-radius: 5px;
          cursor: pointer;

          i {
            font-size: 60px;
            margin-inline: 30px;
          }

          .right-part {
            p {
              margin: 0;
            }
            p:nth-child(1) {
              font-size: 20px;
            }
            p:nth-child(2) {
              font-size: 14px;
            }
          }
        }
      }
      .train-panel-popup-body {
        margin-top: 10px;

        .nav-tabs {
          color: #7a7a7a;
          .nav-link {
            color: $main-color;
          }
          .nav-item {
            &:hover {
              background-color: #042d5a;
              color: white;
              border-radius: 5px;
            }
            a {
              padding: 5px !important;
            }
          }

          .nav-link.active {
            background-color: $main-color2;
            color: white;
            border-radius: 5px;
            &:hover {
              background-color: $main-color2;
              border: 1px solid $main-color2;
            }
          }

          .nav-link:hover {
            color: white !important;
          }
        }

        .tab-content {
          .tab-pane {
            padding: 20px;

            .train-detail-part {
              display: flex;
              .maintenance,
              .left-part {
                width: 50%;
              }

              //Train Detail - Left Part

              .left-part {
                list-style: none;

                li {
                  display: flex;
                  align-items: center;
                  margin-block: 10px !important;
                  color: $main-color;

                  div {
                    width: 50%;
                    display: flex;
                    align-items: center;

                    i {
                      margin-right: 5px;
                      font-size: 16px;
                    }
                  }
                }
              }

              // Maintenance
              .maintenance {
                header {
                  background-color: $main-color;
                  padding-block: 7px;
                  text-align: center;
                  color: white;
                  font-size: 18px;
                }

                .maintenance-body-part {
                  margin-top: 10px;

                  .maintenance-body-part-header {
                    background-color: #f17e31;
                    color: white;
                    padding: 7px;
                    margin-bottom: 5px;
                    font-size: 14px;
                    font-weight: bold;
                    i {
                      margin-right: 3px;
                    }
                  }

                  .maintenance-body-part-body {
                    display: flex;
                    align-items: center;

                    div {
                      margin-inline: 5px;
                    }
                  }
                }
              }
              .danger,
              .success,
              .warning {
                margin: 0;
                justify-content: center;
                color: white;
              }
            }
            //Cerbox
            .cerbox-part {
              padding-block: 5px;
              .cerbox-popup-card {
                border: 1px solid $main-color;
                .cerbox-popup-card-left {
                  background-color: $main-color;
                  padding-block: 20px;
                }
              }
            }
            //ProfileDimension

            .MuiAccordionDetails-root {
              padding-block: 5px !important;
            }

            .MuiAccordionSummary-content {
              margin: 14px !important;
            }
            .profile-dimension-popup {
              border: none;
            }
          }
        }
      }
    }

    .our-sites-panel {
      padding: 15px 10px;
      display: flex;
      align-items: center;
      font-size: 20px;
      background-color: $main-color-hover;
      color: white;
      border-radius: 5px;
      cursor: pointer;
      transition: 0.4s;
      margin-bottom: 15px;

      &:hover {
        background-color: $main-color;
      }

      img {
        width: 40%;
      }
    }

    #ourSites {
      .modal-body {
        padding: 30px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;

        .site-card {
          background-color: $main-color2;
          padding: 20px !important;
          text-align: center;
          color: white;
          font-size: 14px;
          text-decoration: none;
          border-radius: 10px;
          cursor: pointer;
          transform: scale(0.95);
          transition-duration: 0.4s;

          &:hover {
            text-decoration: underline;
            transform: scale(1);
          }
          p {
            margin-bottom: 0;
          }
          p:nth-child(1) {
            font-size: 20px;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .right-side-home {
    .train-service-panel {
      background-color: white;

      .train-service-panel-body {
        padding: 15px 10px 0;
        cursor: grab;
        min-height: 372px;

        .card-border1 {
          border: 1px solid #ffdf05;
        }
        .card-border2 {
          border: 1px solid #108f00;
        }

        .train-service-panel-card {
          margin-bottom: 15px;

          .header1 {
            background-color: #ffdf05;
          }

          .header2 {
            background-color: #108f00;
          }

          .header1,
          .header2 {
            padding: 10px;
            font-size: 18px;
            color: white;
            transition: 0.4s;
          }
          .body {
            padding: 10px 10px 0;
            color: $main-color;
            overflow: scroll;
            max-height: 175px;
            min-height: 100px;

            i {
              margin-right: 4px;
            }
            &::-webkit-scrollbar {
              width: 18px;
              cursor: pointer;
            }

            &::-webkit-scrollbar-track {
              background: transparent;
              border-radius: 3px;
            }

            &::-webkit-scrollbar-thumb {
              border: 5px solid rgba(0, 0, 0, 0);
              background-clip: padding-box;
              border-radius: 9999px;
              box-shadow: inset 0 0 0 10px;
              cursor: pointer;
            }
          }
        }
      }
    }

    .user-info {
      margin-block: 15px;
      background-color: white;

      .panel-header {
        display: flex;
        justify-content: space-between;

        i {
          cursor: pointer;
        }
      }
      .user-info-body {
        text-decoration: none;

        img {
          width: 50%;
          object-fit: fill;
          margin-bottom: 10px;
          margin-inline: auto;
          border-radius: 20px;
        }

        p {
          color: #737373;
        }
      }
    }
    .view-and-suggestion {
      .view-and-suggestion-body {
        padding: 15px 10px;
        text-align: center;
        color: #737373;
        cursor: pointer;
        transition: 0.4s;
        background-color: white;

        &:hover {
          color: $main-color;
        }
        i {
          font-size: 150px;
        }
      }
      .modal-body {
        padding-block: 15px;

        form {
          margin-block: 5px;

          select,
          textarea {
            width: 100%;
            margin-block: 10px;
            padding: 10px;
            outline: none;
            border-color: #939292;
            color: $main-color;
            font-size: 15px;

            &:hover {
              border-color: $main-color;
            }

            &:focus {
              border-color: $main-color2;
            }
          }
        }
      }
    }
  }
}

//AfterLogin Footer
footer {
  background-color: white;
  color: $main-color2;
  margin-top: 10px;
  text-align: center;
  padding-block: 10px;
}

//Responsive
@media only screen and (max-width: 900px) {
  .login {
    .login-body {
      width: 50%;
      form {
        h1 {
          font-size: 30px;
        }
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .login {
    .login-body {
      width: 80%;
    }
  }
}
