body {
  background-color: #faf9f9;
}

body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
a {
  margin: 0;
  padding: 0;
}

.back-side {
  display: flex;

  //Common

  .sidebar {
    .first-sidebar,
    .second-sidebar {
      background: linear-gradient(0deg, #042d5a, #39618f);
      height: 100%;
      position: fixed;
      transition: all 0.4s;

      ul {
        list-style-type: none;
      }
      //Metismenu
      .metismenu {
        background-color: transparent;
        text-align: left;
        position: static;
        margin-block: 15px 0;
        li {
          .metismenu-link {
            padding-inline: 10px;
            font-size: 14px;
            i {
              margin-right: 10px;
              width: 1rem;
            }
          }
          .active {
            background-color: #fa6501;
          }
          .metismenu-link.has-active-child {
            color: white;
          }

          .visible {
            li {
              a {
                font-size: 13px !important;
              }
            }
          }
        }
      }
    }

    .first-sidebar {
      width: 250px;
      padding: 25px 10px;

      .user-image {
        width: 80%;
        margin: auto !important;
        img {
          object-fit: fill;
          border-radius: 50%;
          height: 150px;
          width: 150px;
        }
      }

      .settings-part {
        margin-block: 10px !important;
        color: white;
        font-weight: bold;
      }

      .icons-part {
        text-align: center;

        i {
          margin-inline: 17.5px;
          color: rgb(163, 160, 160);
          transition: all 0.4s;
          font-size: 20px;
          cursor: pointer;

          &:hover {
            color: white;
          }
        }
      }
    }

    .second-sidebar {
      width: 50px;
      padding: 25px 5px;

      .log-out-icon {
        width: 100%;
        text-align: center;
        i {
          cursor: pointer;
          font-size: 17px;
          color: #ddd;
          transition: all 0.4s;
          &:hover {
            color: white;
          }
        }
      }

      //Metismenu
      .metismenu {
        .metismenu-state-icon {
          display: none;
        }
        .metismenu-link {
          padding: 0 !important;
          text-align: center;

          i {
            margin-right: 0 !important;
            font-size: 18px;
            width: 1.5rem !important;
          }
        }

        .visible {
          li {
            a {
              i {
                font-size: 14px !important;
              }
            }
          }
        }
      }
    }
  }

  .right-side {
    transition: all 0.4s;
    padding-top: 20px;
    width: 100%;
    .back-side-header {
      display: flex;
      margin-left: 20px;
      align-items: center;

      a {
        color: #042d5a;
        font-size: 30px;
        font-weight: bold;
        text-decoration: none;
      }

      button {
        background-color: #042e5ad0;
        color: white;
        margin-right: 10px;
        transition: all 0.4s;

        &:hover {
          background-color: #042d5a;
        }
      }
    }

    .back-side-body {
      padding: 20px;

      //Common
      .all-body {
        width: 100%;
        border: 1px solid #ec7424;
        border-radius: 5px 5px 0 0;

        .k-editor {
          width: 75%;
        }

        .news-announcement-image {
          height: 100px;
          object-fit: fill;
          border-radius: 10px;
          max-width: 200px;
        }

        .datatable {
          .pagination {
            li {
              margin-right: 5px !important;
              a {
                padding: 4px !important;
                cursor: pointer;
              }
            }
          }
          th,
          td {
            vertical-align: middle !important;
            padding: none !important;
            text-align: center;
          }

          tr:nth-of-type(odd) {
            background-color: rgba(0, 0, 0, 0.015);
          }

          .custom-select {
            margin-left: 0 !important;
          }
        }

        form {
          padding: 20px 5%;
          font-size: 15px;
          width: 80%;

          .form-part {
            display: flex;
            align-items: center;
            margin-bottom: 15px;

            label {
              width: 20%;
              margin-right: 2%;
              text-align: right;
              font-weight: 500;
              margin-bottom: 0;
            }

            input:disabled {
              width: 78%;
            }

            input[type="text"],
            input[type="email"],
            input[type="number"],
            input[type="date"],
            input[type="password"],
            select,
            textarea {
              width: 78%;
              border: 1px solid #042d5a;
              border-radius: 4px;
              padding: 4px;
              outline: none;
              transition: 0.4s;
              color: #042d5a;
              font-size: 14px;

              &:is(:focus, :hover) {
                border-color: #ec7424;
              }
            }
            i {
              margin-left: -27px;
              cursor: pointer;
            }
          }

          button {
            font-size: 15px;
            outline: none;
          }
        }

        //pass data
        .pass-data {
          display: flex;
          padding: 16px;
          align-items: center;

          a {
            color: #fa6501;
          }

          form {
            width: 50%;
          }
          .response-messages-pass-data {
            width: 45%;
            font-size: 13px;
            padding: 20px;
          }
        }

        .suggestion-rows {
          width: 100%;
          display: flex;
          font-size: 15px;
          margin-block: 10px;

          p {
            margin-bottom: 0;
          }

          p:nth-child(odd) {
            width: 20%;
            text-align: right;
            color: #7e7e7e;
            font-weight: 500;
          }
        }
      }

      .back-side-body-part-header {
        background-color: #ec7424;
        color: white;
        padding: 7px 10px;
        font-weight: 500;
      }

      .button-add,
      .button-delete,
      .button-update,
      .button-detail {
        color: white;
        font-weight: 500;
        padding: 7px 10px !important;
        border-radius: 5px;
        font-size: 14px;
        border: none;
        text-decoration: none;
        transition: all 0.4s;
        display: inline-block;
      }

      .button-add {
        background-color: rgba(0, 128, 0, 0.75);
        margin-bottom: 20px !important;
        &:hover {
          background-color: green;
        }
      }

      .button-detail {
        background-color: #042e5acc;
        &:hover {
          background-color: #042d5a;
        }
      }

      .button-delete {
        background-color: rgba($color: #dc3546, $alpha: 0.75);

        &:hover {
          background-color: #dc3546;
        }
      }

      .button-update {
        background-color: rgba($color: #ec7424, $alpha: 0.75);

        &:hover {
          background-color: #ec7424;
        }
      }

      .back-side-body-header {
        background-color: white;
        padding: 20px;
        width: 100%;
        color: #042d5a;
        font-size: 28px;
        font-weight: 500;
        margin-bottom: 20px;

        i {
          cursor: pointer;
          transition: all 0.4s;
          &:hover {
            color: #ec7424;
          }
        }
      }
    }
  }
}

.cerbox-modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  overflow: hidden;
  z-index: 15000;
  .cerbox-modal-body {
    width: 500px;
    // height: 400px;
    background-color: white;
    margin: auto;
    margin-top: 100px;
    border-radius: 20px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 10px 20px;

      p {
        font-size: 25px;
        font-weight: 600;
        color: #042d5a;
      }

      button {
        border-radius: 10px;
        outline: none;
        border: none;
        background-color: #042d5a;
        color: white;
        padding: 5px 20px;
        font-size: 18px;
      }
    }
    .body {
      display: flex;
      flex-direction: column;
      gap: 30px;
      width: 100%;
      padding: 30px;
      p {
        color: #042d5a;
      }
      input{
        border-radius: 5px;
        border: 1px solid #042d5a;
        padding: 2px 10px;
      }
      button {
        border-radius: 10px;
        outline: none;
        border: none;
        background-color: #042d5a;
        color: white;
        padding: 5px 20px;
        font-size: 18px;
      }
    }
    .cerbox-error{
      padding: 20px!important;
    }
  }
}
