@media only screen and (max-width: 900px) {
  .home {
    display: block!important;

    .left-side-home,
    .right-side-home,
    .middle-home {
      width: 95%!important;
      margin-inline: auto!important;
    }

    .left-side-home {
      .our-sites-panel {
        padding-block: 10px!important;

        img {
          width: 25%!important;
        }

        h4 {
          font-size: 30px!important;
        }
      }
    }
    .menu-flags {
    margin-top: 10px;
    }
  }

  .back-side {
    .all-body {
      overflow-x: auto!important;
      form {
        width: 100% !important;
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  .modal-body {
    .modal-body-image {
      width: 100% !important;
      img {
        width: 100% !important;
      }
    }
  }

  .home {
    .left-side-home {
      .modal {
        .success,
        .warning,
        .danger {
          font-size: 14px!important;
        }

        .makeStyles-heading-2 {
          flex-basis: 100%!important;
        }

        .bakim-popup-card {
          .bakim-popup-card-left div {
            font-size: 15px !important;
            padding-inline: 4px;
          }
          .bakim-popup-card-right {
            font-size: 14px!important;
          }
        }

        .cerbox-popup-card {
          .cerbox-popup-card-left div {
            font-size: 16px!important;
          }
        }
        .profile-dimension-popup {
          .profile-dimension-popup-body {
            grid-template-columns: repeat(1, 1fr) !important;

            .warning,
            .success,
            .danger {
              min-width: 80px !important;
            }
          }
        }

        .MuiPaper-root {
          min-width: 300px!important;
          overflow-x: auto!important;
        }
        .modal-body {
          overflow-x: auto !important;
          font-size: 14px !important;
        }

        .train-detail-part {
              display: block!important;
              .maintenance,
              .left-part {
                width: 100%!important;
                margin-bottom: 30px!important;
              }
            }        
      }
      #ourSites .modal-body{
        grid-template-columns: repeat(1, 1fr)!important;
      }
    }

    .middle-home {
      .news {
        .panel-header {
          display: block !important;
          text-align: center!important;
        }
        .news-body {
          .swiper-button-prev:after {
            font-size: 25px !important;
          }
        }
      }

      .announcement {
        .announcement-panel-body {
          .announcement-body-card {
            display: block !important;
            text-align: center;
            img {
              width: 80% !important;
              margin-right: 0 !important;
            }
            .announcement-content {
              margin-bottom: 5px!important;
            }
          }
        }
      }
    }
  }

  .back-side {
    .right-side {
      .back-side-body {
        .button-delete,
        .button-add {
          margin-bottom: 5px !important;
        }
        .all-body {
          form {
            .form-part {
              display: block !important;
              label {
                width: 100% !important;
                text-align: left !important;
                margin-bottom: 5px !important;
              }
              input,
              select,
              .k-editor,
              img {
                width: 100% !important;
              }
            }
          }

          .suggestion-rows {
            p:nth-child(odd) {
              width: 30% !important;
            }
          }

          //pass data
          .pass-data {
            display: block !important;

            form {
              width: 100% !important;
            }
            .response-messages-pass-data {
              width: 100% !important;
            }
          }
        }
      }
      .back-side-header {
        .icon-button {
          display: none;
        }
      }
    }

    .sidebar {
      .first-sidebar {
        display: none !important;
      }
    }
  }
}
